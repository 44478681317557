import React from 'react'
import { Spinner, Center } from '@chakra-ui/react'

const FullPageSpinner = ({ h = "100vh" }) => {
    return (
        <Center w="full" h={h}>
            <Spinner />
        </Center>
    )
}

export default FullPageSpinner
