import { extendTheme } from "@chakra-ui/react";
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';

const theme = extendTheme({
    colors: {
        brand: {
            primary: {
                1: "#305253",
                2: "#397E80",
            },
            secondary: {
                1: "#EFD0BC",
                2: "#F7F5F2"
            },
            gray: {
                1: "#F3F5F5",
                2: "#C5C8CB",
                3: "#8B8F95",
                4: "#44444F",
                5: "#212121",
            },
            white: "#FFFFFF",
            success: "#48BF84",
            warning: "#FF8F59",
            error: "#E54B4B",
            pastel: {
                1: "#54A6AD",
                2: "#9BD1D2",
                3: "#9A5F5C",
                4: "#F7DDDB",
                5: "#E06754",
                6: "#E1B75E",
                7: "#E5F1F4",
                8: "#AC8FB8"
            }
        }
    },
    fonts: {
        heading: "Inter",
        body: "Inter",
    },
    components: {
        Avatar: {
            sizes: {
                sm: {
                    container: {
                        width: "34px",
                        height: "34px"
                    }
                },
                xl: {
                    container: {
                        width: "84px",
                        height: "84px"
                    }
                }
            }
        },
        ServiceCard: {
            variants: {
                store: {

                },
                group: {

                }
            }
        },
        Steps,
    },
    styles: {
        global: {
            button: {
                fontFamily: "Inter",
                letterSpacing: "0.1px"
            }
        }
    }
})
export default theme