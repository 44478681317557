import { auth } from "../firebaseConfig";

const getAuthToken = async forceRefresh => {
  const token = await auth.currentUser.getIdToken(forceRefresh);
  return token;
};

const api = async (
  endpoint,
  { body, forceRefreshToken = false, ...customConfig } = {}
) => {
  const token = await getAuthToken(forceRefreshToken);
  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
    config.headers["Content-Length"] = config.body.length;
  }

  return fetch(
    `${process.env.REACT_APP_SPLITGENIE_API_BASE}/${endpoint}`,
    config
  ).then(async response => {
    if (response.status === 401) {
      auth.signOut();
      return;
    }
    if (response.ok) {
      return await response.json();
    } else {
      const errorMessage = await response.json();
      return Promise.reject(new Error(errorMessage.error));
    }
  });
};

const signupUser = ({ name, state, city, photoURL }) => {
  return api("signupUser", {
    body: {
      id: auth.currentUser.uid,
      email: auth.currentUser.email,
      emailVerified: auth.currentUser.emailVerified,
      phoneNumber: auth.currentUser.phoneNumber,
      name,
      state,
      city,
      photoURL,
    },
  });
};
const updateUser = data => {
  return api("updateUser", {
    body: {
      id: auth.currentUser.uid,
      ...data,
    },
  });
};

export { api, signupUser, updateUser };
