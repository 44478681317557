import React, { useContext, useState, useEffect, createContext } from "react";
import FullPageSpinner from "../Components/FullPageSpinner";
import { auth as firebaseAuth, db } from "../firebaseConfig";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { api, updateUser } from "../Utils/api";

import {
  onAuthStateChanged,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut as firebaseSignOut,
  sendSignInLinkToEmail as firebaseSendSignInLinkToEmail,
  isSignInWithEmailLink as firebaseIsSignInWithEmailLink,
  signInWithEmailLink as firebaseSignInWithEmailLink,
  signInWithPopup,
  GoogleAuthProvider,
  PhoneAuthProvider,
  updatePhoneNumber as firebaseUpdatePhoneNumber,
  updateProfile as firebaseUpdateProfile,
  reload,
} from "firebase/auth";

const AuthContext = createContext();
AuthContext.displayName = "AuthContext";

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(true);
  let recaptchaVerifier;
  const google = {};

  const errorMessages = {
    "auth/invalid-phone-number": "The phone number entered is invalid",
    "auth/code-expired": "This code has expired. Please request a new code.",
    "auth/account-exists-with-different-credential":
      "This phone number/email is already in use with another account",
    "auth/quota-exceeded":
      "Phone verification is facing problems. Please try again later or use other methods of login",
    "auth/user-disabled": "The user has been disabled",
    "auth/captcha-check-failed": "Please refresh the browser and try again",
    "auth/invalid-email": "The email address entered is invalid",
    "auth/expired-action-code": "The passwordless link has expired",
    "auth/popup-closed-by-user": "The popup has been closed",
    "auth/too-many-requests": "Too many requests. Try again later",
    "auth/invalid-verification-code": "This code is invalid.",
    default: "Some error has occured",
  };

  const getAuthErrorMessage = code =>
    errorMessages[code] ? errorMessages[code] : errorMessages.default;

  const initializeRecaptcha = element => {
    recaptchaVerifier = new RecaptchaVerifier(
      element,
      {
        size: "invisible",
        callback: response => {
          console.log("Recaptcha Verified");
        },
        "expired-callback": () => {
          console.log("Recaptcha Expired");
        },
        "error-callback": error => {
          console.log("Recaptcha Error");
        },
      },
      firebaseAuth
    );
  };

  function resetRecaptcha() {
    return recaptchaVerifier.clear();
  }
  const verifyPhoneNumber = phoneNumber => {
    const phoneProvider = new PhoneAuthProvider(firebaseAuth);
    return phoneProvider.verifyPhoneNumber(phoneNumber, recaptchaVerifier);
  };
  const updateProfile = data => {
    const formattedData = {};

    if (data.hasOwnProperty("name")) formattedData.displayName = data.name;
    if (data.hasOwnProperty("photoURL")) formattedData.photoURL = data.photoURL;
    return firebaseUpdateProfile(firebaseAuth.currentUser, formattedData).then(
      async d => {
        await api("updateUser", {
          method: "PATCH",
          body: { fields: Object.keys(data) },
          forceRefreshToken: true,
        });
        await refreshUser();
        return data;
      }
    );
  };
  const updatePhoneNumber = (verificationId, verificationCode) => {
    const phoneCredential = PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    return firebaseUpdatePhoneNumber(
      firebaseAuth.currentUser,
      phoneCredential
    ).then(async data => {
      await api("updateUser", {
        method: "PATCH",
        body: { fields: ["phoneNumber"] },
        forceRefreshToken: true,
      });
      await refreshUser();
      return data;
    });
  };
  const signInWithPhone = phoneNumber =>
    signInWithPhoneNumber(firebaseAuth, phoneNumber, recaptchaVerifier);

  const sendSignInLinkToEmail = email => {
    const actionCodeSettings = {
      url: `${window.location.origin}/login/email`,
      handleCodeInApp: true,
    };
    return firebaseSendSignInLinkToEmail(
      firebaseAuth,
      email,
      actionCodeSettings
    );
  };
  const isSignInWithEmailLink = () =>
    firebaseIsSignInWithEmailLink(firebaseAuth, window.location.href);

  const signInWithEmailLink = email =>
    firebaseSignInWithEmailLink(firebaseAuth, email, window.location.href);

  const signInWithPopupGoogle = () => {
    google.provider = new GoogleAuthProvider();
    return new Promise((resolve, reject) => {
      signInWithPopup(firebaseAuth, google.provider)
        .then(result => {
          google.credential = GoogleAuthProvider.credentialFromResult(result);
          google.token = google.credential.accessToken;
          return result;
        })
        .then(result => resolve(result))
        .catch(error => reject(error));
    });
  };

  const signOut = () => firebaseSignOut(firebaseAuth);

  const getUser = async currentUser => {
    if (currentUser) {
      setLoadingStatus(true);
      const userDoc = await getDoc(doc(db, "users", currentUser.uid));
      if (userDoc.exists()) setUser({ ...currentUser, data: userDoc.data() });
      else setUser({ ...currentUser, data: null });
    } else setUser(null);
    setLoadingStatus(false);
  };

  const refreshUser = async () => {
    if (!user) return;
    await reload(firebaseAuth.currentUser);
    const userDoc = await getDoc(doc(db, "users", user.uid));
    if (userDoc.exists())
      setUser({ ...firebaseAuth.currentUser, data: userDoc.data() });
    else setUser(user => ({ ...firebaseAuth.currentUser, data: user.data }));
  };

  const updateUserData = async (
    updatedData = {},
    replace = false,
    sync = false
  ) => {
    if (sync) {
      //upload to DB and firestore auth profile using server
      return await updateUser(updatedData).then(() => getUser(user));
    } else {
      return setUser(currentUser => {
        Object.keys(updatedData).forEach(key => {
          if (currentUser.data.hasOwnProperty(key)) {
            if (replace) currentUser.data[key] = updatedData[key];
            else {
              if (Array.isArray(currentUser.data[key]))
                currentUser.data[key] = [
                  ...currentUser.data[key],
                  ...updatedData[key],
                ];
              else if (
                typeof currentUser.data[key] === "object" &&
                currentUser.data[key] !== null
              )
                currentUser.data[key] = {
                  ...currentUser.data[key],
                  ...updatedData[key],
                };
              else currentUser.data[key] = updatedData[key];
            }
          }
        });
        return currentUser;
      });
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, currentUser =>
      getUser(currentUser)
    );
    return unsubscribe;
  }, []);

  return loadingStatus ? (
    <FullPageSpinner />
  ) : (
    <AuthContext.Provider
      value={{
        user,
        initializeRecaptcha,
        resetRecaptcha,
        signInWithPhone,
        signOut,
        sendSignInLinkToEmail,
        isSignInWithEmailLink,
        signInWithEmailLink,
        signInWithPopupGoogle,
        getAuthErrorMessage,
        getUser,
        refreshUser,
        updateUserData,
        verifyPhoneNumber,
        updatePhoneNumber,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export { AuthProvider, useAuth };
